import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React, { Suspense } from 'react'
import '../styles/theme.css'
import Footer from './footer'
import Header from './header/index'
import { useLocation } from '@reach/router'

const CartSidebar = React.lazy(() => import('../components/cart/cart-sidebar'))

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const location = useLocation()

  console.log(`got location -> `, location)
  return location.pathname.match('landing') ? (
    <div>
      <main>{children}</main>
    </div>
  ) : (
    <div>
      <Suspense fallback={<div />}>
        <CartSidebar />
      </Suspense>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
