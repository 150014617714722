import { Link } from 'gatsby'
import React, { useState, Fragment } from 'react'
import Logo from '../../icons/logo.svg'
import AccountPopover from './account-popover'
import Banner from './banner'
import CartPopover from './cart-popover'
import HeaderLink from './header-link'
import MobileMenu from './mobile-menu'
import RegionPopover from './region-popover'
import { useCart } from '../../hooks/use-cart'

/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Popover, Tab, Transition } from '@headlessui/react'
import {
  BookmarkAltIcon,
  BriefcaseIcon,
  ChartBarIcon,
  CheckCircleIcon,
  CursorClickIcon,
  DesktopComputerIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  MenuIcon,
  NewspaperIcon,
  OfficeBuildingIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  ViewGridIcon,
  XIcon,
  SearchIcon,
  ShoppingBagIcon
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'

const navigation = {
  categories: [
    {
      id: 'women',
      name: 'Women',
      featured: [
        {
          name: 'New Arrivals',
          href: '#',
          imageSrc:
            'https://tailwindui.com/img/ecommerce-images/mega-menu-category-01.jpg',
          imageAlt:
            'Models sitting back to back, wearing Basic Tee in black and bone.'
        },
        {
          name: 'Basic Tees',
          href: '#',
          imageSrc:
            'https://tailwindui.com/img/ecommerce-images/mega-menu-category-02.jpg',
          imageAlt:
            'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.'
        }
      ],
      sections: [
        {
          id: 'clothing',
          name: 'Clothing',
          items: [
            { name: 'Tops', href: '#' },
            { name: 'Dresses', href: '#' },
            { name: 'Pants', href: '#' },
            { name: 'Denim', href: '#' },
            { name: 'Sweaters', href: '#' },
            { name: 'T-Shirts', href: '#' },
            { name: 'Jackets', href: '#' },
            { name: 'Activewear', href: '#' },
            { name: 'Browse All', href: '#' }
          ]
        },
        {
          id: 'accessories',
          name: 'Accessories',
          items: [
            { name: 'Watches', href: '#' },
            { name: 'Wallets', href: '#' },
            { name: 'Bags', href: '#' },
            { name: 'Sunglasses', href: '#' },
            { name: 'Hats', href: '#' },
            { name: 'Belts', href: '#' }
          ]
        },
        {
          id: 'brands',
          name: 'Brands',
          items: [
            { name: 'Full Nelson', href: '#' },
            { name: 'My Way', href: '#' },
            { name: 'Re-Arranged', href: '#' },
            { name: 'Counterfeit', href: '#' },
            { name: 'Significant Other', href: '#' }
          ]
        }
      ]
    },
    {
      id: 'men',
      name: 'Men',
      featured: [
        {
          name: 'New Arrivals',
          href: '#',
          imageSrc:
            'https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg',
          imageAlt:
            'Drawstring top with elastic loop closure and textured interior padding.'
        },
        {
          name: 'Artwork Tees',
          href: '#',
          imageSrc:
            'https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-06.jpg',
          imageAlt:
            'Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.'
        }
      ],
      sections: [
        {
          id: 'clothing',
          name: 'Clothing',
          items: [
            { name: 'Tops', href: '#' },
            { name: 'Pants', href: '#' },
            { name: 'Sweaters', href: '#' },
            { name: 'T-Shirts', href: '#' },
            { name: 'Jackets', href: '#' },
            { name: 'Activewear', href: '#' },
            { name: 'Browse All', href: '#' }
          ]
        },
        {
          id: 'accessories',
          name: 'Accessories',
          items: [
            { name: 'Watches', href: '#' },
            { name: 'Wallets', href: '#' },
            { name: 'Bags', href: '#' },
            { name: 'Sunglasses', href: '#' },
            { name: 'Hats', href: '#' },
            { name: 'Belts', href: '#' }
          ]
        },
        {
          id: 'brands',
          name: 'Brands',
          items: [
            { name: 'Re-Arranged', href: '#' },
            { name: 'Counterfeit', href: '#' },
            { name: 'Full Nelson', href: '#' },
            { name: 'My Way', href: '#' }
          ]
        }
      ]
    }
  ],
  pages: [
    { name: 'Company', href: '#' },
    { name: 'Stores', href: '#' }
  ]
}

const mockData = {
  customer: {
    first_name: 'Kasper',
    last_name: 'F. Kristensen'
  },
  cart: {
    currency_code: 'DKK',
    items: [
      {
        title: 'Medusa Tote',
        amount: 12500,
        quantity: 1,
        thumbnail:
          'https://medusa-public-images.s3.eu-west-1.amazonaws.com/tshirt.png'
      },
      {
        title: 'Medusa Cover',
        amount: 9000,
        quantity: 1,
        thumbnail:
          'https://medusa-public-images.s3.eu-west-1.amazonaws.com/tshirt.png'
      },
      {
        title: 'Medusa Sweatshirt',
        amount: 28000,
        quantity: 2,
        thumbnail:
          'https://medusa-public-images.s3.eu-west-1.amazonaws.com/tshirt.png'
      }
    ]
  },
  regions: [
    {
      id: '1',
      name: 'Denmark',
      currency_code: 'DKK',
      countries: [
        {
          display_name: 'Denmark'
        }
      ]
    },
    {
      id: '2',
      name: 'Norway',
      currency_code: 'NOK',
      countries: [
        {
          display_name: 'Norway'
        }
      ]
    },
    {
      id: '3',
      name: 'Europe',
      currency_code: 'EUR',
      countries: [
        {
          display_name: 'Germany'
        },
        {
          display_name: 'France'
        },
        {
          display_name: 'Italy'
        },
        {
          display_name: 'Spain'
        }
      ]
    }
  ]
}

const solutions = [
  {
    name: 'Analytics',
    description:
      'Get a better understanding of where your traffic is coming from.',
    href: '#',
    icon: ChartBarIcon
  },
  {
    name: 'Engagement',
    description: 'Speak directly to your customers in a more meaningful way.',
    href: '#',
    icon: CursorClickIcon
  },
  {
    name: 'Security',
    description: "Your customers' data will be safe and secure.",
    href: '#',
    icon: ShieldCheckIcon
  },
  {
    name: 'Integrations',
    description: "Connect with third-party tools that you're already using.",
    href: '#',
    icon: ViewGridIcon
  }
]
const callsToAction = [
  { name: 'Watch Demo', href: '#', icon: PlayIcon },
  { name: 'View All Products', href: '#', icon: CheckCircleIcon },
  { name: 'Contact Sales', href: '#', icon: PhoneIcon }
]
const company = [
  { name: 'About', href: '#', icon: InformationCircleIcon },
  { name: 'Customers', href: '#', icon: OfficeBuildingIcon },
  { name: 'Press', href: '#', icon: NewspaperIcon },
  { name: 'Careers', href: '#', icon: BriefcaseIcon },
  { name: 'Privacy', href: '#', icon: ShieldCheckIcon }
]
const resources = [
  { name: 'Community', href: '#', icon: UserGroupIcon },
  { name: 'Partners', href: '#', icon: GlobeAltIcon },
  { name: 'Guides', href: '#', icon: BookmarkAltIcon },
  { name: 'Webinars', href: '#', icon: DesktopComputerIcon }
]
const blogPosts = [
  {
    id: 1,
    name: 'Boost your conversion rate',
    href: '#',
    preview:
      'Eget ullamcorper ac ut vulputate fames nec mattis pellentesque elementum. Viverra tempor id mus.',
    imageUrl:
      'https://images.unsplash.com/photo-1558478551-1a378f63328e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2849&q=80'
  },
  {
    id: 2,
    name: 'How to use search engine optimization to drive traffic to your site',
    href: '#',
    preview:
      'Eget ullamcorper ac ut vulputate fames nec mattis pellentesque elementum. Viverra tempor id mus.',
    imageUrl:
      'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2300&q=80'
  }
]

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

export const HeaderLayout2 = () => {
  const [open, setOpen] = useState(false)
  const {
    cart,
    actions: { onClickOpen }
  } = useCart()

  return (
    <Popover className='relative bg-white'>
      <div
        className='absolute inset-0 shadow z-30 pointer-events-none'
        aria-hidden='true'
      />
      <div className='relative z-20'>
        <div className='max-w-7xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10'>
          <div>
            <a href='#' className='flex'>
              <span className='sr-only'>Workflow</span>
              <img
                width='44px'
                height='40px'
                className='h-8 w-auto sm:h-10'
                src='https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg'
                alt=''
              />
            </a>
          </div>
          <div className='-mr-2 -my-2 md:hidden'>
            <Popover.Button className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
              <span className='sr-only'>Open menu</span>
              <MenuIcon className='h-6 w-6' aria-hidden='true' />
            </Popover.Button>
          </div>
          <div className='hidden md:flex-1 md:flex md:items-center md:justify-between'>
            <Popover.Group as='nav' className='flex space-x-10'>
              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? 'text-gray-900' : 'text-gray-500',
                        'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      )}
                    >
                      <span>Solutions</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? 'text-gray-600' : 'text-gray-400',
                          'ml-2 h-5 w-5 group-hover:text-gray-500'
                        )}
                        aria-hidden='true'
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-200'
                      enterFrom='opacity-0 -translate-y-1'
                      enterTo='opacity-100 translate-y-0'
                      leave='transition ease-in duration-150'
                      leaveFrom='opacity-100 translate-y-0'
                      leaveTo='opacity-0 -translate-y-1'
                    >
                      <Popover.Panel className='hidden md:block absolute z-10 top-full inset-x-0 transform shadow-lg bg-white'>
                        <div className='max-w-7xl mx-auto grid gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16'>
                          {solutions.map(item => (
                            <a
                              key={item.name}
                              href={item.href}
                              className='-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50'
                            >
                              <div className='flex md:h-full lg:flex-col'>
                                <div className='flex-shrink-0'>
                                  <span className='inline-flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12'>
                                    <item.icon
                                      className='h-6 w-6'
                                      aria-hidden='true'
                                    />
                                  </span>
                                </div>
                                <div className='ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4'>
                                  <div>
                                    <p className='text-base font-medium text-gray-900'>
                                      {item.name}
                                    </p>
                                    <p className='mt-1 text-sm text-gray-500'>
                                      {item.description}
                                    </p>
                                  </div>
                                  <p className='mt-2 text-sm font-medium text-indigo-600 lg:mt-4'>
                                    Learn more{' '}
                                    <span aria-hidden='true'>&rarr;</span>
                                  </p>
                                </div>
                              </div>
                            </a>
                          ))}
                        </div>
                        <div className='bg-gray-50'>
                          <div className='max-w-7xl mx-auto space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8'>
                            {callsToAction.map(item => (
                              <div key={item.name} className='flow-root'>
                                <a
                                  href={item.href}
                                  className='-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100'
                                >
                                  <item.icon
                                    className='flex-shrink-0 h-6 w-6 text-gray-400'
                                    aria-hidden='true'
                                  />
                                  <span className='ml-3'>{item.name}</span>
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              <a
                href='#'
                className='text-base font-medium text-gray-500 hover:text-gray-900'
              >
                Pricing
              </a>
              <a
                href='#'
                className='text-base font-medium text-gray-500 hover:text-gray-900'
              >
                Docs
              </a>
              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? 'text-gray-900' : 'text-gray-500',
                        'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      )}
                    >
                      <span>More</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? 'text-gray-600' : 'text-gray-400',
                          'ml-2 h-5 w-5 group-hover:text-gray-500'
                        )}
                        aria-hidden='true'
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-200'
                      enterFrom='opacity-0 -translate-y-1'
                      enterTo='opacity-100 translate-y-0'
                      leave='transition ease-in duration-150'
                      leaveFrom='opacity-100 translate-y-0'
                      leaveTo='opacity-0 -translate-y-1'
                    >
                      <Popover.Panel className='hidden md:block absolute z-10 top-full inset-x-0 transform shadow-lg'>
                        <div className='absolute inset-0 flex'>
                          <div className='bg-white w-1/2' />
                          <div className='bg-gray-50 w-1/2' />
                        </div>
                        <div className='relative max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2'>
                          <nav className='grid gap-y-10 px-4 py-8 bg-white sm:grid-cols-2 sm:gap-x-8 sm:py-12 sm:px-6 lg:px-8 xl:pr-12'>
                            <div>
                              <h3 className='text-sm font-medium tracking-wide text-gray-500 uppercase'>
                                Company
                              </h3>
                              <ul role='list' className='mt-5 space-y-6'>
                                {company.map(item => (
                                  <li key={item.name} className='flow-root'>
                                    <a
                                      href={item.href}
                                      className='-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50'
                                    >
                                      <item.icon
                                        className='flex-shrink-0 h-6 w-6 text-gray-400'
                                        aria-hidden='true'
                                      />
                                      <span className='ml-4'>{item.name}</span>
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div>
                              <h3 className='text-sm font-medium tracking-wide text-gray-500 uppercase'>
                                Resources
                              </h3>
                              <ul role='list' className='mt-5 space-y-6'>
                                {resources.map(item => (
                                  <li key={item.name} className='flow-root'>
                                    <a
                                      href={item.href}
                                      className='-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50'
                                    >
                                      <item.icon
                                        className='flex-shrink-0 h-6 w-6 text-gray-400'
                                        aria-hidden='true'
                                      />
                                      <span className='ml-4'>{item.name}</span>
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </nav>
                          <div className='bg-gray-50 px-4 py-8 sm:py-12 sm:px-6 lg:px-8 xl:pl-12'>
                            <div>
                              <h3 className='text-sm font-medium tracking-wide text-gray-500 uppercase'>
                                From the blog
                              </h3>
                              <ul role='list' className='mt-6 space-y-6'>
                                {blogPosts.map(post => (
                                  <li key={post.id} className='flow-root'>
                                    <a
                                      href={post.href}
                                      className='-m-3 p-3 flex rounded-lg hover:bg-gray-100'
                                    >
                                      <div className='hidden sm:block flex-shrink-0'>
                                        <img
                                          className='w-32 h-20 object-cover rounded-md'
                                          src={post.imageUrl}
                                          alt=''
                                        />
                                      </div>
                                      <div className='w-0 flex-1 sm:ml-8'>
                                        <h4 className='text-base font-medium text-gray-900 truncate'>
                                          {post.name}
                                        </h4>
                                        <p className='mt-1 text-sm text-gray-500'>
                                          {post.preview}
                                        </p>
                                      </div>
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div className='mt-6 text-sm font-medium'>
                              <a
                                href='#'
                                className='text-indigo-600 hover:text-indigo-500'
                              >
                                View all posts
                                <span aria-hidden='true'>&rarr;</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </Popover.Group>
            <span className='flex md:grow' />

            <div className='flex items-center md:ml-12'>
              <Popover.Group>
                <RegionPopover />
                <AccountPopover customer={mockData.customer} />
              </Popover.Group>
            </div>
            <button
              onClick={onClickOpen}
              className='-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50'
            >
              <span>
                <ShoppingBagIcon />
              </span>
              <span>{cart.items.reduce((sum, i) => sum + i.quantity, 0)}</span>
            </button>
            <CartPopover cart={mockData.cart} />
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter='duration-200 ease-out'
        enterFrom='opacity-0 scale-95'
        enterTo='opacity-100 scale-100'
        leave='duration-100 ease-in'
        leaveFrom='opacity-100 scale-100'
        leaveTo='opacity-0 scale-95'
      >
        <Popover.Panel
          focus
          className='absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden'
        >
          <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50'>
            <div className='pt-5 pb-6 px-5 sm:pb-8'>
              <div className='flex items-center justify-between'>
                <div>
                  <img
                    className='h-8 w-auto'
                    src='https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg'
                    alt='Workflow'
                  />
                </div>
                <div className='-mr-2'>
                  <Popover.Button className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                    <span className='sr-only'>Close menu</span>
                    <XIcon className='h-6 w-6' aria-hidden='true' />
                  </Popover.Button>
                </div>
              </div>
              <div className='mt-6 sm:mt-8'>
                <nav>
                  <div className='grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4'>
                    {solutions.map(item => (
                      <a
                        key={item.name}
                        href={item.href}
                        className='-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50'
                      >
                        <div className='flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12'>
                          <item.icon className='h-6 w-6' aria-hidden='true' />
                        </div>
                        <div className='ml-4 text-base font-medium text-gray-900'>
                          {item.name}
                        </div>
                      </a>
                    ))}
                  </div>
                  <div className='mt-8 text-base'>
                    <a
                      href='#'
                      className='font-medium text-indigo-600 hover:text-indigo-500'
                    >
                      {' '}
                      View all products <span aria-hidden='true'>&rarr;</span>
                    </a>
                  </div>
                </nav>
              </div>
            </div>
            <div className='py-6 px-5'>
              <div className='grid grid-cols-2 gap-4'>
                <a
                  href='#'
                  className='rounded-md text-base font-medium text-gray-900 hover:text-gray-700'
                >
                  Pricing
                </a>

                <a
                  href='#'
                  className='rounded-md text-base font-medium text-gray-900 hover:text-gray-700'
                >
                  Docs
                </a>

                <a
                  href='#'
                  className='rounded-md text-base font-medium text-gray-900 hover:text-gray-700'
                >
                  Company
                </a>

                <a
                  href='#'
                  className='rounded-md text-base font-medium text-gray-900 hover:text-gray-700'
                >
                  Resources
                </a>

                <a
                  href='#'
                  className='rounded-md text-base font-medium text-gray-900 hover:text-gray-700'
                >
                  Blog
                </a>

                <a
                  href='#'
                  className='rounded-md text-base font-medium text-gray-900 hover:text-gray-700'
                >
                  Contact Sales
                </a>
              </div>
              <div className='mt-6'>
                <a
                  href='#'
                  className='w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700'
                >
                  Sign up
                </a>
                <p className='mt-6 text-center text-base font-medium text-gray-500'>
                  Existing customer?{' '}
                  <a href='#' className='text-indigo-600 hover:text-indigo-500'>
                    Sign in
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
