import { Menu } from '@headlessui/react'
import React from 'react'
import { useRegion } from '../../hooks/use-region'
import { useRegionDisabled } from '../../hooks/use-region-disabled'
import { classNames } from '../../utils/class-names'
import PopoverTransition from '../popover-transition'
import { ChevronDownIcon } from '@heroicons/react/solid'

import 'flag-icons/css/flag-icons.min.css'
import 'currency-flags/dist/currency-flags.min.css'

const CurrencyIcon = props => (
  <div className={`currency-flag currency-flag-${props.code}`} />
)

const FlagIcon = props => <span className={`fi fi-${props.code}`} />

const RegionPopover = () => {
  const {
    region,
    country: selected,
    regions,
    actions: { updateRegion }
  } = useRegion()

  const disabled = useRegionDisabled()

  const handleSelect = async (region, country) => {
    updateRegion(region, country)
  }

  const open = false
  return (
    <Menu
      as='div'
      className={classNames(
        disabled ? 'hidden' : 'inline-block',
        'relative text-left'
      )}
    >
      <Menu.Button className='inline-flex justify-center w-full px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:text-gray-600'>
        <a
          href='#'
          className='text-gray-700 hover:text-gray-800 flex items-center'
        >
          <FlagIcon code={selected?.iso_2} />
          <span className='ml-3 block text-sm font-medium'>
            {region?.currency_code?.toUpperCase()}
          </span>
          <span>
            <ChevronDownIcon
              className={`ml-2 h-5 w-5 group-hover:text-gray-500`}
              aria-hidden='true'
            />
          </span>
          <span className='sr-only'>, change currency</span>
        </a>
      </Menu.Button>

      <PopoverTransition>
        <Menu.Items className='origin-top-right absolute right-0 mt-2 w-56 px-6 py-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
          {regions?.map(region => {
            return region.countries.map((country, i) => {
              return (
                <div className='border-b border-ui last:border-b-0' key={i}>
                  <Menu.Item>
                    {() => (
                      <button
                        onClick={() => handleSelect(region, country)}
                        className={classNames(
                          selected?.iso_2 === country.iso_2
                            ? 'text-black'
                            : 'text-ui-dark',
                          'block py-3 text-sm w-full text-left hover:text-black'
                        )}
                      >
                        <span>
                          <FlagIcon code={country.iso_2} />
                        </span>
                        <span className={`ml-3`}>{country.display_name}</span>
                      </button>
                    )}
                  </Menu.Item>
                </div>
              )
            })
          })}
        </Menu.Items>
      </PopoverTransition>
    </Menu>
  )
}

export default RegionPopover
